import { Tooltip, defaultStyles } from '@visx/tooltip';

export default function Details({
  formatPrice,
  formatNumber,
  bucket,
  xScale,
  width
}) {
  const halfway = xScale.range()[1] / 2;
  const color = bucket.hollow ? "#e23b3b" : "#36C05C"
  return (
    <Tooltip
      style={{
        ...defaultStyles,
        background: 'none',
        borderRadius: 0,
        boxShadow: 'none',
        color: color,
        zIndex: 1000,
        padding: 0,
        width: width, 
        transform: xScale(bucket.date) > halfway ? "translate(-104%)" : "",
      }}
      top={-14}
      left={20}
    >
      <div className="details">
        <div className="details-row">
        <div className="detail">
          <div className="tooltip-label">H</div>
          <div className="tooltip-value">
            {formatPrice(bucket.high)}
          </div>
        </div>
        <div className="detail">
          <div className="tooltip-label">L</div>
          <div className="tooltip-value">
            {formatPrice(bucket.low)}
          </div>
        </div>
        <div className="detail">
          <div className="tooltip-label">O</div>
          <div className="tooltip-value">
            {formatPrice(bucket.open)}
          </div>
        </div>
        <div className="detail">
          <div className="tooltip-label">C</div>
          <div className="tooltip-value">
            {formatPrice(bucket.close)}
          </div>
        </div>
      </div>
      <div className="detail">
        <div className="tooltip-label">Volume</div>
        <div className="tooltip-value">
          {formatNumber(bucket.volume)}
        </div>
      </div>
    </div>
    </Tooltip>
  );
}
