export const SET_DEBOT = "SET_DEBOT";
export const SET_PAIR = "SET_PAIR";

export const SET_DEBOTS = "SET_DEBOTS";
export const SET_PAIRS = "SET_PAIRS";
export const SET_NORMALIZED_PAIRS = "SET_NORMALIZED_PAIRS";
export const SET_ORDERBOOK = "SET_ORDERBOOK";
export const SET_TRADES = "SET_TRADES";

export const SELECT_ORDERBOOK_ROW = "SELECT_ORDERBOOK_ROW";
export const SET_EXCHANGE_MODE = "SET_EXCHANGE_MODE";

export const SET_ORDER_MESSAGE = "SET_ORDER_MESSAGE";

export const SET_LINEAR_CHART = "SET_LINEAR_CHART";
export const SET_PRICE_LINEAR_CHART = "SET_PRICE_LINEAR_CHART";
export const SET_CANDLESTICK_CHART = "SET_CANDLESTICK_CHART";

