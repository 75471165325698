import callback from "./abi";
import moment from "moment";
import {
  address,
  OrderBookRow,
  XchgOrderBookRow,
  TradeHistory,
  Tip3Tip3PairInfo,
  Tip3TonPairInfo,
  Pairs,
  DeBot,
  LinearChart,
  CandlestickChart
 } from "../interfaces";
const browser = import("debot-browser");

(async () => {
  console.log((await browser).run_debot_browser);
})()

const logger = console;

// export const DeBots:Array<DeBot> = [
//   {
//     title: 'Devnet',
//     name: 'net.ton.dev',
//     address: '0:e47e0efbd07d588e5bcf820221cd976527ddcc59b3a9760144a1ad72d5257ac9'
//   },
// ]

export const DeBots:Array<DeBot> = [
  {
    title: 'Mainnet',
    name: 'main.ton.dev',
    address: '0:f207fcab308c52a75226212d58dcfbc146cc7c1df1be16046200432f062a0822'
  },
  {
    title: 'Devnet',
    name: 'net.ton.dev',
    address: '0:f207fcab308c52a75226212d58dcfbc146cc7c1df1be16046200432f062a0822'
  },
]

export default class DeBotClient {
  static convert = (from: any, to: any) => (data: any) => Buffer.from(data, from).toString(to);

  static hexToUtf8 = DeBotClient.convert('hex', 'utf8');
  static utf8ToHex = DeBotClient.convert('utf8', 'hex');


//  private static debot = "0:e47e0efbd07d588e5bcf820221cd976527ddcc59b3a9760144a1ad72d5257ac9";
  private static debot = "0:f207fcab308c52a75226212d58dcfbc146cc7c1df1be16046200432f062a0822";
  private static buildManifest = (func: any, args: any) => {
  return `{
    "version": 0,
    "debotAddress": "${DeBotClient.debot}",
    "initMethod": "${func}",
    "initArgs": ${args},
    "quiet": true,
    "chain": [],
    "abi": ${callback.abi}
  }`;
  };

  static async getPairs(debot: DeBot = DeBots[0]):Promise<Pairs> {
    logger.log(`Calling getPairs...\n`);
    const manifest = DeBotClient.buildManifest("getPairs", "{}");
    const result = (await browser).run_debot_browser(debot.name, null, null, null, manifest)
    .then(result => JSON.parse(result))
    .catch(error => error);
    return await result;
  }

  static async getOrderBook(pair: Tip3TonPairInfo, debot: DeBot = DeBots[0]):Promise<{orderBook: OrderBookRow[]}> {
    logger.log(`Calling getOrderBook for ${DeBotClient.hexToUtf8(pair.symbol)}...\n`);
    const manifest = DeBotClient.buildManifest("getOrderBook", `{"tradingPair":"${pair.tradingPair}"}`);
    return await (await browser).run_debot_browser(debot.name, null, null, null, manifest);
  }

  static async getXchgOrderBook(pair: Tip3Tip3PairInfo, debot: DeBot = DeBots[0]):Promise<{orderBook: XchgOrderBookRow[]}> {
    logger.log(`Calling getOrderBook for ${DeBotClient.hexToUtf8(pair.symbolMajor)} and ${DeBotClient.hexToUtf8(pair.symbolMinor)}...\n`);
    const manifest = DeBotClient.buildManifest("getXchgOrderBook", `{"xchgTradingPair":"${pair.xchgTradingPair}"}`);
    return await (await browser).run_debot_browser(debot.name, null, null, null, manifest);
  }

  static async getPriceTickHistory(pair: Tip3TonPairInfo, debot: DeBot = DeBots[0]):Promise<{history: TradeHistory}> {
    logger.log(`Calling getPriceTickHistory for ${DeBotClient.hexToUtf8(pair.symbol)}...\n`);
    const manifest = DeBotClient.buildManifest("getPriceTickHistory", `{"tradingPair":"${pair.tradingPair}","startTime":0}`);
    return await (await browser).run_debot_browser(debot.name, null, null, null, manifest);
  }

  static async getXchgPriceTickHistory(pair: Tip3Tip3PairInfo, debot: DeBot = DeBots[0]):Promise<{history: TradeHistory}> {
    logger.log(`Calling getPriceTickHistory for ${DeBotClient.hexToUtf8(pair.symbolMajor)}...\n`);
    const manifest = DeBotClient.buildManifest("getXchgPriceTickHistory", `{"xchgTradingPair":"${pair.xchgTradingPair}","startTime":0}`);
    return await (await browser).run_debot_browser(debot.name, null, null, null, manifest);
  }

  static async getBuyOrderMsg(tradingPairAddress: address = "", price: string = '600000000', volume: string = '5', debot: DeBot = DeBots[0]):Promise<{message: string}> {
    logger.log(`\n\nCalling getBuyOrderMsg...\n`);
    logger.log(`\n\n{"tradingPair":"${tradingPairAddress}","price":${price},"volume":${volume}}`);
    const manifest = DeBotClient.buildManifest("getBuyOrderMsg", `{"tradingPair":"${tradingPairAddress}","price":${price},"volume":${volume}}`);
    return await (await browser).run_debot_browser(debot.name, null, null, null, manifest);
  }

  static async getSellOrderMsg(tradingPairAddress: address = "", price: string = '600000000', volume: string = '5', debot: DeBot = DeBots[0]):Promise<{message: string}> {
    logger.log(`\n\nCalling getSellOrderMsg...\n`);
    logger.log(`\n\n{"tradingPair":"${tradingPairAddress}","price":${price},"volume":${volume}}`);
    const manifest = DeBotClient.buildManifest("getSellOrderMsg", `{"tradingPair":"${tradingPairAddress}","price":${price},"volume":${volume}}`);
    return await (await browser).run_debot_browser(debot.name, null, null, null, manifest);
  }


  static async getLinearChart(
    pair: Tip3TonPairInfo,
    debot: DeBot = DeBots[0],
    startTime: any = moment().subtract(4, 'hours').unix(),
    endTime: any = moment().unix(),
    stepTime: any = 300,
  ):Promise<{prices: LinearChart}> {
    logger.log(`\n\nCalling getLinearChart...\n`);
    logger.log(`startTime":${startTime.toString()},"endTime":${endTime.toString()}","stepTime":${stepTime}})`);
    const manifest = DeBotClient.buildManifest(
      "getLinearChart",
      `{"tradingPair":"${pair.tradingPair}","startTime":${startTime},"endTime":${endTime},"stepTime":${stepTime}}`);
    return await (await browser).run_debot_browser(debot.name, null, null, null, manifest);
  }

  static async getXchgLinearChart(
    pair: Tip3Tip3PairInfo,
    debot: DeBot = DeBots[0],
    startTime: any = moment().subtract(4, 'hours').unix(),
    endTime: any = moment().unix(),
    stepTime: any = 300,
  ):Promise<{prices: LinearChart}> {
    logger.log(`\n\nCalling getXchgLinearChart...\n`);
    logger.log(`startTime":${startTime.toString()},"endTime":${endTime.toString()}","stepTime":${stepTime}})`);
    const manifest = DeBotClient.buildManifest(
      "getXchgLinearChart",
      `{"xchgTradingPair":"${pair.xchgTradingPair}","startTime":${startTime},"endTime":${endTime},"stepTime":${stepTime}}`);
    return await (await browser).run_debot_browser(debot.name, null, null, null, manifest);
  }

  static async getCandlestickChart(pair: Tip3TonPairInfo, debot: DeBot = DeBots[0], startTime: any = moment().subtract(4, 'hours').unix(), endTime: any = moment().unix(), stepTime: any = 1800):Promise<{candles: CandlestickChart}> {
    logger.log(`\n\nCalling getCandlestickChart...\n`);
    const manifest = DeBotClient.buildManifest("getCandlestickChart", `{"tradingPair":"${pair.tradingPair}","startTime":${startTime},"endTime":${endTime},"stepTime":${stepTime}}`);
    return await (await browser).run_debot_browser(debot.name, null, null, null, manifest);
  }

  static async getXchgCandlestickChart(pair: Tip3Tip3PairInfo, debot: DeBot = DeBots[0], startTime: any = moment().subtract(4, 'hours').unix(), endTime: any = moment().unix(), stepTime: any = 1800):Promise<{candles: CandlestickChart}> {
    logger.log(`\n\nCalling getXchgCandlestickChart...\n`);
    const manifest = DeBotClient.buildManifest("getXchgCandlestickChart", `{"xchgTradingPair":"${pair.xchgTradingPair}","startTime":${startTime},"endTime":${endTime},"stepTime":${stepTime}}`);
    return await (await browser).run_debot_browser(debot.name, null, null, null, manifest);
  }
}
