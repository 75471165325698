import React from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import cn from "classnames";
import {
  isMobile
} from "react-device-detect";

import Landing from "./landing";
import Main from "./main";
import Content from "./content";
import Footer from "./footer";
import Header from "./header";
import Config from '../../config';

const Router:React.FunctionComponent<{}> = () => {
  const location:string = useLocation().pathname.split('/').filter(Boolean)[0];
  return (
    <div className={cn("ws-app", useLocation().pathname.split('/').filter(Boolean)[0], {"isMobile": isMobile, "index-page": !location})}>
      <Header/>
      <main>
        <Switch>
          <Route path="/legal-notes/:id">
            <Content />
          </Route>
          <Route path="/" render={(props) => {
                return Config.isLandingPage ? <Landing/> : <Main/>;
            }} />
          <Redirect to='/'/>
        </Switch>
      </main>
      <Footer />
    </div>
  );
};

export default Router;
