import React, { useCallback, useRef, useMemo } from 'react';
import { Group } from '@visx/group';
import { LinearGradient } from '@visx/gradient';
import { scaleTime, scaleLinear } from '@visx/scale';

import { AreaClosed, LinePath, Bar } from '@visx/shape';
import { curveCardinal } from '@visx/curve';
import { withTooltip,  } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { GridRows } from '@visx/grid';
import { AxisBottom, AxisRight } from '@visx/axis';
import { localPoint } from '@visx/event';
import { bisector } from 'd3-array';

import { withParentSize } from '@visx/responsive';
import { WithParentSizeProvidedProps } from '@visx/responsive/lib/enhancers/withParentSize';

import { ReadableLinearData } from '../../../interfaces';

import HoverLineLinear from './components/hoverlineLinear';
import ValueMarker from './components/valuemarker';
import TimeMarker from './components/xmarker';

import moment from 'moment';
const formatDate = (value: string) => moment(value).format("h:mm A");


interface TooltipData {
    date: string;
    price: number;
    volume: number;
    name?: string;
    min?: number;
    median?: number;
    max?: number;
    firstQuartile?: number;
    thirdQuartile?: number;
  }

  export type ChartProps = {
    data: ReadableLinearData[]
  };
  
  export type WithParentSizeProps = {
    debounceTime?: number;
    enableDebounceLeadingCall?: boolean;
  };

  export default withParentSize<WithParentSizeProps & ChartProps>(withTooltip<ChartProps, TooltipData>(
    ({
      data,
      parentWidth = 600,
      parentHeight = 400,
      tooltipLeft,
      tooltipTop,
      tooltipData,
      showTooltip,
      hideTooltip
    }: ChartProps & WithParentSizeProvidedProps & WithTooltipProvidedProps<TooltipData>) => {

    if (
      !parentWidth ||
      !data ||
      !data.length
    ) return <div style={{padding: 15}}>Loading...</div>;

    const svgContainer = useRef<SVGSVGElement>(null);

    const margin = {
      top: 50,
      bottom: 40,
      left: 30,
      right: 75,
    }
    const width = parentWidth - margin.right;
    const height = parentHeight - margin.top - margin.bottom;

    const data_ = data;


    const date = (d:ReadableLinearData) => moment(d.date).toDate();
    const price = (d:ReadableLinearData) => d.price;

    const firstPoint = data_[0];
    const currentPoint = data_[data_.length - 1];
    const minPrice = Math.min(...data_.map(price));
    const maxPrice = Math.max(...data_.map(price));

    const xScale = useMemo(
      () =>
      scaleTime<number>({
          range: [0, width],
          domain: [date(firstPoint), date(currentPoint)]
        }),
      [width, data_],
    );

    const yScale = useMemo(
      () =>{
        if (minPrice === 0 && maxPrice === 0) return scaleLinear<number>({
          range: [height, 0],
          domain: [0, 16]
        })
        return scaleLinear<number>({
          range: [height, 0],
          domain: [minPrice, maxPrice]
        })
      },
      [height, minPrice, maxPrice, data_],
    );

    const bisectDate = bisector<any, Date>(d => new Date(d.date)).left;
  
    const handleTooltip = useCallback(
      (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
        const { x } = localPoint(event) || { x: 0 };
        const x0 = xScale.invert(x);
        const index = bisectDate(data_, x0, 1);
        const d0 = data_[index - 1];
        const d1 = data_[index];
        let d = d0;
        if (d1 && date(d1)) {
          d = x0.valueOf() - date(d0).valueOf() > date(d1).valueOf() - x0.valueOf() ? d1 : d0;
        }

        showTooltip({
          tooltipData: d,
          tooltipLeft: xScale(date(d)),
          tooltipTop: yScale(price(d)) + margin.top,
        });
      },
      [showTooltip, yScale, xScale, data_],
    );



    return (<div style={{position: "relative"}}>
      <svg
        width={parentWidth}
        height={parentHeight}
        ref={svgContainer}
      >

        <Group top={margin.top} left={0}>
          <GridRows
            width={width}
            height={height}
            scale={yScale}
            numTicks={8}
          />
        
        </Group>
           <Group top={margin.top} left={0} width={parentWidth} height={parentHeight}>
            <AxisBottom
              scale={xScale}
              top={height + 10}
              left={0}
              numTicks={6}
              hideAxisLine
              hideTicks
              tickLabelProps={() => ({
                fill: "#707376",
                fontSize: 13,
                textAnchor: 'middle',
              })}
            />
            <AxisRight
              scale={yScale}
              top={5}
              left={width + 30}
              numTicks={8}
              hideAxisLine
              hideTicks
              tickLabelProps={() => ({
                fill: "#707376",
                fontSize: 13,
                textAnchor: 'middle',
              })}
            />
  

            <LinearGradient
              id="area-gradient"
              from="#0083E0"
              to="#0083E0"
              vertical={true}
              toOpacity={0}
              fromOpacity={0.15}
            />

            <LinePath<ReadableLinearData>
               data={data_}
               y={(y) => yScale(price(y))}
               x={(x) => xScale(date(x))}
               stroke="#0083E0"
               strokeOpacity="1"
               strokeLinejoin='round'
               strokeWidth={2}
               curve={curveCardinal}
             />
             
             <AreaClosed<ReadableLinearData>
               data={data_}
               y={(y) => yScale(price(y))}
               x={(x) => xScale(date(x))}
               yScale={yScale}
               strokeLinejoin='round'
               strokeWidth={0}
               fill="url(#area-gradient)"
               curve={curveCardinal}
             />
            
            <Bar
              width={width}
              height={height}
              fill="transparent"
              onTouchStart={handleTooltip}
              onTouchMove={handleTooltip}
              onMouseMove={handleTooltip}
              onMouseLeave={() => {
                hideTooltip()
              }}
            />
           </Group>

           {tooltipData && <>
            <HoverLineLinear
              size={{
                width: width,
                height: height + margin.bottom + 20
              }}
              tooltipLeft={tooltipLeft}
              tooltipTop={tooltipTop}
            />
            </>}

      </svg>

      {tooltipData &&
        <div>
          <TimeMarker
            top={height + margin.top + 7}
            left={tooltipLeft}
            format={formatDate}
            style={{}}
            value={date(tooltipData).valueOf()}
          />
          <ValueMarker
            top={tooltipTop}
            left={width + 3}
            style={{}}
            format={(value:any) => value.toFixed(4)}
            value={price(tooltipData)}
          />
        </div>}
      </div>);
    },
  )
);