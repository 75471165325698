import { Dispatch, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ActionTypes, RootState } from "../../types";
import DeBotClient, { DeBots } from "../../client/client";

import {
    setDeBots,
    setOrderMessage
} from "../../redux/actions";

import { 
  address,
  OrderBookRow,
  PairInfo,
  OrderMessage,
  DeBot
 } from "../../interfaces";

export interface IUseDashboard {
  selectedPair: PairInfo | undefined,
  selectedDeBot: DeBot | undefined,
  selectedOrderbookRow: OrderBookRow | undefined,
  exchangeMode: string,
  setExchangeMode: any,
  chartMode: string,
  setChartMode: any,
  setBuySellDeeplink: any,
  orderMessage: OrderMessage | undefined
}

export const useDashboard = (): IUseDashboard => {
  const dispatch = useDispatch<Dispatch<ActionTypes>>();
  const { selectedPair, selectedDeBot, selectedOrderbookRow, orderMessage } = useSelector((state: RootState) => state.dashboard);
  const [ exchangeMode, setExchangeMode ] = useState<string>('buy');
  const [ chartMode, setChartMode ] = useState<string>('chart');

  const setBuySellDeeplink:(tradingPair:address, price: string, volume: string, callback?: any) => void = (tradingPair, price = '0', volume = '0', callback = () => {}) => {
    if (exchangeMode === "buy") {
      //console.log('Msg buy');
      DeBotClient.getBuyOrderMsg(tradingPair, price, volume, selectedDeBot)
        .then((message) => dispatch(setOrderMessage({
          mode: exchangeMode,
          message: (JSON.parse(String(message))).message
        })))
        .then(() => callback());
      } else {
        //console.log('Msg sell');
      DeBotClient.getSellOrderMsg(tradingPair, price, volume, selectedDeBot)
        .then((message) => dispatch(setOrderMessage({
          mode: exchangeMode,
          message: (JSON.parse(String(message))).message
        })))
        .then(() => callback());
      }
  }

  useEffect(() => {
    dispatch(setDeBots(DeBots));
  }, [dispatch]);

  return useMemo(
    () => ({
      selectedPair,
      selectedDeBot,
      selectedOrderbookRow,

      exchangeMode,
      setExchangeMode,

      chartMode,
      setChartMode,

      setBuySellDeeplink,
      orderMessage
    }),
    [selectedPair, selectedDeBot, selectedOrderbookRow, exchangeMode, chartMode, setChartMode, orderMessage],
  );
};
