import React, { useCallback, useRef, useMemo } from 'react';
import { Group } from '@visx/group';
import { LinearGradient } from '@visx/gradient';
import { scaleLinear } from '@visx/scale';

import { AreaClosed, LinePath, Bar } from '@visx/shape';
import { curveStepAfter } from '@visx/curve';
import { withTooltip } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { GridRows } from '@visx/grid';
import { AxisBottom, AxisRight } from '@visx/axis';
import { localPoint } from '@visx/event';
import { bisector } from 'd3-array'; 

import { withParentSize } from '@visx/responsive';
import { WithParentSizeProvidedProps } from '@visx/responsive/lib/enhancers/withParentSize';

import HoverLineLinear from './components/hoverlineLinear';
import ValueMarker from './components/valuemarker';
import TimeMarker from './components/xmarker';

interface TooltipData {
    price: number;
    buyVolume: number;
    sellVolume: number;
    name?: string;
    min?: number;
    median?: number;
    max?: number;
    firstQuartile?: number;
    thirdQuartile?: number;
  }

type ReadableOrderLinearData = {
  price: number,
  buyVolume: number,
  sellVolume: number
}

  export type ChartProps = {
    data: Array<ReadableOrderLinearData>
  };

  
export type WithParentSizeProps = {
  debounceTime?: number;
  enableDebounceLeadingCall?: boolean;
};

  export default withParentSize<WithParentSizeProps & ChartProps>(withTooltip<ChartProps, TooltipData>(
    ({
      data,
      parentWidth = 600,
      parentHeight = 400,
      tooltipLeft,
      tooltipTop,
      tooltipData,
      showTooltip,
      hideTooltip
    }: ChartProps & WithParentSizeProvidedProps & WithTooltipProvidedProps<TooltipData>) => {

    if (
      !parentWidth ||
      !data ||
      !data.length
    ) return <div style={{padding: 15}}>Loading...</div>;

    const svgContainer = useRef<SVGSVGElement>(null);

    const margin = {
      top: 50,
      bottom: 40,
      left: 30,
      right: 75,
    }
    const width = parentWidth - margin.right;
    const height = parentHeight - margin.top - margin.bottom;

    const price = (d:ReadableOrderLinearData) => d.price;
    const buyVolume = (d:ReadableOrderLinearData) => d.buyVolume;
    const sellVolume = (d:ReadableOrderLinearData) => d.sellVolume;

    const data_ = data.filter(value => price(value) || buyVolume(value) || sellVolume(value));

    const firstPoint = data_[0];
    const currentPoint = data_[data_.length - 1];
    const minVolume = Math.min(...data_.map(buyVolume), ...data_.map(sellVolume));
    const maxVolume = Math.max(...data_.map(buyVolume), ...data_.map(sellVolume));

    const xScale = useMemo(
      () =>
      scaleLinear<number>({
          range: [0, width],
          domain: [price(firstPoint), price(currentPoint)]
        }),
      [width, data_],
    );

    const yScale = useMemo(
      () =>{
        if (minVolume === 0 && maxVolume === 0) return scaleLinear<number>({
          range: [height, 0],
          domain: [0, 16]
        })
        return scaleLinear<number>({
          range: [height, 0],
          domain: [minVolume, maxVolume]
        })
      },
      [height, minVolume, maxVolume, data_],
    );

    const bisectPrice = bisector<any, number>(price).left;
  
    const handleTooltip = useCallback(
      (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
        const { x } = localPoint(event) || { x: 0 };
        const x0 = xScale.invert(x);
        const index = bisectPrice(data_, x0, 1);
        const d0 = data_[index - 1];
        const d1 = data_[index];
        let d = d0;
        if (d1 && price(d1)) {
          d = x0 - price(d0) > price(d1) - x0 ? d1 : d0;
        }

        showTooltip({
          tooltipData: d,
          tooltipLeft: xScale(price(d)),
          tooltipTop: yScale(buyVolume(d) || sellVolume(d)) + margin.top,
        });
      },
      [showTooltip, yScale, xScale, data_],
    );

    const sellData = data_.filter((value) => value.buyVolume === 0);
    const buyData = data_.filter((value) => value.sellVolume === 0);

    if (buyData.length) sellData.unshift(buyData[buyData.length - 1]);

    return (<div style={{position: "relative"}}>
      <svg
        width={parentWidth}
        height={parentHeight}
        ref={svgContainer}
      >

        <Group top={margin.top} left={0}>
          <GridRows
            width={width}
            height={height}
            scale={yScale}
            numTicks={8}
          />
        
        </Group>
           <Group top={margin.top} left={0} width={parentWidth} height={parentHeight}>
            <AxisBottom
              scale={xScale}
              top={height + 10}
              left={0}
              numTicks={6}
              hideAxisLine
              hideTicks
              tickLabelProps={() => ({
                fill: "#707376",
                fontSize: 13,
                textAnchor: 'middle',
              })}
            />
            <AxisRight
              scale={yScale}
              top={5}
              left={width + 30}
              numTicks={8}
              hideAxisLine
              hideTicks
              tickLabelProps={() => ({
                fill: "#707376",
                fontSize: 13,
                textAnchor: 'middle',
              })}
            />
  

            <LinearGradient
              id="area-green-gradient"
              from="#36C05C"
              to="#36C05C"
              vertical={true}
              toOpacity={0}
              fromOpacity={0.25}
            />

            <LinearGradient
              id="area-red-gradient"
              from="#e23b3b"
              to="#e23b3b"
              vertical={true}
              toOpacity={0}
              fromOpacity={0.15}
            />

            {buyData && buyData.length && <>
            <LinePath<ReadableOrderLinearData>
               data={buyData}
               y={(y) => yScale(buyVolume(y))}
               x={(x) => xScale(price(x))}
               stroke="#36C05C"
               strokeOpacity="1"
               strokeLinejoin='round'
               strokeWidth={2}
               curve={curveStepAfter}
             />
             
             <AreaClosed<ReadableOrderLinearData>
               data={buyData}
               y={(y) => yScale(buyVolume(y))}
               x={(x) => xScale(price(x))}
               yScale={yScale}
               strokeLinejoin='round'
               strokeWidth={0}
               fill="url(#area-green-gradient)"
               curve={curveStepAfter}
             />
            </>}

            {sellData && sellData.length && <>
              <LinePath<ReadableOrderLinearData>
                data={sellData}
                y={(y) => yScale(sellVolume(y))}
                x={(x) => xScale(price(x))}
                stroke="#e23b3b"
                strokeOpacity="1"
                strokeLinejoin='round'
                strokeWidth={2}
                curve={curveStepAfter}
              />
              
              <AreaClosed<ReadableOrderLinearData>
                data={sellData}
                y={(y) => yScale(sellVolume(y))}
                x={(x) => xScale(price(x))}
                yScale={yScale}
                strokeLinejoin='round'
                strokeWidth={0}
                fill="url(#area-red-gradient)"
                curve={curveStepAfter}
              />
             </>}
            
            <Bar
              width={width}
              height={height}
              fill="transparent"
              onTouchStart={handleTooltip}
              onTouchMove={handleTooltip}
              onMouseMove={handleTooltip}
              onMouseLeave={() => {
                hideTooltip()
              }}
            />
           </Group>

           {tooltipData && <>
            <HoverLineLinear
              size={{
                width: width,
                height: height + margin.bottom + 20
              }}
              tooltipLeft={tooltipLeft}
              tooltipTop={tooltipTop}
            />
            </>}

      </svg>

      {tooltipData &&
          <div>
            <TimeMarker
              top={height + margin.top + 7}
              left={tooltipLeft}
              format={(value:any) => value.toFixed(5)}
              style={{}}
              value={price(tooltipData)}
            />
            <ValueMarker
              top={tooltipTop}
              left={width + 3}
              style={{}}
              format={(value:any) => value.toFixed(3)}
              value={buyVolume(tooltipData) || sellVolume(tooltipData)}
            />
          </div>}
    </div>);
    },
  )
);