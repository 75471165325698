import { Tooltip, defaultStyles } from '@visx/tooltip';

export default function Marker({ top, left, value, format, style }) {
  return (
    <Tooltip
      style={{
        ...defaultStyles,
        ...style,

        borderRadius: 5,
        padding: "3px 5px",
        boxShadow: '0 1px 10px rgba(0,0,0,0.1)',
        transition: '0.15s cubic-bezier(0.375, 0.885, 0.6, 1) all',
        backgroundColor: '#27273f',
        color: 'white',
        fontSize: '13px',
      }}
      top={top}
      left={left}
    >
      <div>
        {format(value)}
      </div>
    </Tooltip>
  );
}
