import { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
// import cn from "classnames";
// import { Provider } from "react-redux";

import Router from "./views/router";
//import "bootstrap/scss/bootstrap.scss";
//import "bootstrap/scss/_accordion.scss";
import "../assets/styles/bootstrap/bootstrap.min.css";
import "../assets/styles/bootstrap/_variables.scss";
import "../assets/styles/index.scss";
// import { store } from "../../redux/store/configure-store";
// import "../../assets/style/common.sass";
// import "../../assets/style/font.sass";
// import "../../assets/style/v-app.sass";
// import "../../assets/style/media.sass";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { store } from "../redux/store/configure-store";
import { Provider } from "react-redux";

const App = () => {
  const [isHidden, setIsHidden] = useState(true);

  // const removePreSpinner = () => {
  //     const spinnerDiv = document.getElementById('spinner-container');
  //     if (spinnerDiv) {
  //         spinnerDiv.remove();
  //     }
  // };

  // const refresh = () => {
  //     setIsHidden(true);
  //     setImmediate(() => setIsHidden(false));
  // };

  useEffect(() => {
      // langListenerDisposer = reaction(
      //     () => localizationService.language,
      //     refresh,
      //     { name: 'Language switch reaction' },
      // );

      // themeListenerDisposer = reaction(
      //     () => themesService.isDarkTheme,
      //     () => {
      //         refresh();
      //         pageHistory.pop();
      //     },
      //     { name: 'Theme toggle reaction' },
      // );

      (async () => {

          // await Configs.setup();

          setIsHidden(false);
          // KeyboardService.addListener();
          // setTimeout(() => {
          //     removePreSpinner();
          // }, 0);
      })();
  }, []);

  if (isHidden) {
      return null;
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Provider>
  );
};



export default App;
