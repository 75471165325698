import { FunctionComponent, useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { ReactComponent as Logo } from "../../assets/images/logo-flash.svg";
import { Link } from "react-router-dom";
import { useHeader } from "../controllers/useHeader";
import Config from '../../config';

const Layout: FunctionComponent<{}> = (props) => {
  const { pairs, selectedPair, setSelectedPair, debots, selectedDeBot, setSelectedDeBot, location } = useHeader();
  const [expanded, setExpanded] = useState<boolean>(false);

  if (Config.isLandingPage) return (
    <header>
      <Navbar expand="sm" expanded>
        <Navbar.Brand>
        <Link to="/" className="logo">
            <Logo/>
            <span>Flash Exchange</span>
        </Link>
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          </Nav>
          <Nav.Link
            href={`${Config.flexUrl}?debot=mainnet`}
            target="_blank"
            className="btn-icon btn-icon-arrow-up-right"
          >
            Launch app
          </Nav.Link>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
  return (
    <header>
      <Navbar expand="sm" expanded={expanded}>
        <Navbar.Brand>
          <Link to="/" onClick={() => setExpanded(false)} className="logo">
            <Logo/>
            <span>Flash Exchange</span>
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : true)} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title={selectedDeBot ? selectedDeBot.title : "Loading..."} id="net-nav-dropdown">
              {debots.map((debot, index) => <NavDropdown.Item onClick={() => {
                  setSelectedDeBot(debot);
                  window.history.pushState(null, '', `${window.location.pathname}?debot=${debot.title.toLowerCase()}`);
              }} key={index}>{debot.title}</NavDropdown.Item>)}
            </NavDropdown>
            <NavDropdown title={selectedPair ? `${selectedPair.nameMajor} / ${selectedPair.nameMinor}` : 'Loading...'} id="currency-nav-dropdown">
              {pairs.map((pair, index) => <NavDropdown.Item onClick={() => {
                  setSelectedPair(pair);
                  const debotParam = selectedDeBot ? `debot=${selectedDeBot.title.toLowerCase()}&` : '';
                  window.history.pushState(null, '', `${window.location.pathname}?${debotParam}tradingPair=${pair.tradingPair}`);
              }} key={index}>{`${pair.nameMajor} / ${pair.nameMinor}`}</NavDropdown.Item>)}
            </NavDropdown>
          </Nav>
          <Nav.Link href={Config.landingUrl} target="_blank" onClick={() => setExpanded(false)} className="btn-icon btn-icon-arrow-up-right">Home</Nav.Link>
          <Nav.Link href={Config.docsUrl} target="_blank" onClick={() => setExpanded(false)} className="btn-icon btn-icon-arrow-up-right">Help</Nav.Link>
          <Nav.Link href="https://ton.surf/get" target="_blank" className="btn-icon btn-icon-arrow-up-right" onClick={() => setExpanded(false)}>Get Surf</Nav.Link>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Layout;
