// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Container, Row, Col, NavDropdown } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import MetaDecorator from "../utils/metaDecorator";
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native-web';
import Config from '../../config';
import { useHeader } from "../controllers/useHeader";
import DeBotClient, { DeBots } from "../../client/client";
import {
    setDeBots
} from "../../redux/actions";
import {
  PairInfo,
  DeBot
 } from "../../interfaces";

const showStats = false;

const styles = StyleSheet.create({
    cardValueTitle: {
        lineHeight: 48,
        fontWeight: 600,
        fontSize: 30,
        color: 'rgb(32, 38, 42)',
    },
    cardValueText: {
        lineHeight: 24,
        fontWeight: 400,
        fontSize: 17,
        color: 'rgb(32, 38, 42)',
    },
    cardTitle: {
        lineHeight: 24,
        fontWeight: 700,
        fontSize: 20,
        color: 'rgb(0, 0, 0)',
    },
    cardText: {
        lineHeight: 20,
        fontWeight: 400,
        fontSize: 16,
        color: 'rgb(54, 64, 70)',
    },
    flex: {
        display: 'flex',
    },
});

function getTrades() {}

function get24HPrice(pair, debot, cb) {
    const then = (value: { prices: any}) => {
        const payload = typeof value === 'string' ? JSON.parse(value) : value;
        const priceLinearChart = payload.prices;
        const data: any[] = [];
        for (const key in priceLinearChart) {
          data.push({
            date: moment.unix(parseInt(key)).format("YYYY/MM/DD HH:mm:ss"),
            price: parseFloat(DeBotClient.hexToUtf8(priceLinearChart[key].price)),
            volume  : parseFloat(DeBotClient.hexToUtf8(priceLinearChart[key].volume))
          });
        }
        cb(data);
    }
    if (pair.type === "Tip3TonPairInfo") {
        DeBotClient.getLinearChart({
              symbol: pair.symbolMajor,
              decimals: pair.decimalsMajor,
              tradingPair: pair.tradingPair
            },
            debot,
            moment().subtract(24, 'hours').unix(),
            moment().unix(),
            3600,
        )
        .then(then);
    } else {
        DeBotClient.getXchgLinearChart({
            symbolMajor: pair.symbolMajor,
            symbolMinor: pair.symbolMinor,
            decimalsMajor: pair.decimalsMajor,
            decimalsMinor: pair.decimalsMinor,
            xchgTradingPair: pair.tradingPair
          },
          debot,
          moment().subtract(24, 'hours').unix(),
          moment().unix(),
          3600,
      )
      .then(then);
  }
}


const DebotTable:React.FunctionComponent<{}> = (props) => {
    const { pairs, debots, selectedDeBot, setSelectedDeBot } = useHeader();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setDeBots(DeBots));
    },[dispatch]);

    return (
      <Row>
        <Col xs={{ span: 0, order: 1 }} sm={{ span: 0, order: 1 }} md={{ span: 0, order: 1 }} lg={{ span: 3 }} xl={{ span: 3 }}/>
        <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <View style={{marginTop: 64}} />
            {selectedDeBot &&
            <NavDropdown title={selectedDeBot.title} id="net-nav-dropdown" style={{fontWeight: 700, fontSize: 20, padding: 0, marginLeft: -16}}>
              {debots.map((debot, index) => <NavDropdown.Item onClick={() => {
                  setSelectedDeBot(debot);
              }} key={index}>{debot.title}</NavDropdown.Item>)}
            </NavDropdown>
            }
                <View style={[styles.flex, {flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: 'rgb(223, 227, 235)'}, {marginTop: 8}]}>
                    <Text style={[{flex: 1}, styles.cardTitle, {fontSize: 18, fontWeight: 400}]}>
                        {`Assets`}
                    </Text>
                    <Text style={[{flex: 1}, styles.cardTitle, {fontSize: 18, fontWeight: 400}]}>
                        {`Last Trade`}
                    </Text>
                    <Text style={[{flex: 1}, styles.cardTitle, {fontSize: 18, fontWeight: 400}]}>
                        {`24H %`}
                    </Text>
                    <Text style={[{flex: 1}, styles.cardTitle, {fontSize: 18, fontWeight: 400}]}>
                        {`24H Change`}
                    </Text>
                    <Text style={[{flex: 1}]}>
                        {``}
                    </Text>
                </View>
            {(!pairs || !pairs.length) &&
                <View style={{alignItems: 'center', flex:1, marginTop: 32}}>
                    <Spinner animation="border" variant="primary" />
                </View>
            }
            {pairs && pairs.map && pairs.map((pair, index) => <Pair key={pair.tradingPair} pair={pair} debot={selectedDeBot}/>)}
        </Col>
        <Col xs={{ span: 0, order: 1 }} sm={{ span: 0, order: 1 }} md={{ span: 0, order: 1 }} lg={{ span: 3 }} xl={{ span: 3 }}/>
      </Row>
    );
}

export type PairProps = {
  key: string;
  pair: PairInfo;
  debot: DeBot | undefined;
};

const Pair:React.FunctionComponent<PairProps> = (props: PairProps) => {
    const [history, setHistory] = useState<any>(null);
    const [prices, setPrices] = useState<any>([]);
    useEffect(() => {
      if (!prices || !prices.length)
          get24HPrice(props.pair, props.debot, (prices)=>{
              setPrices(prices);
          });

      if (!history && props.pair.type === "Tip3TonPairInfo") {
        DeBotClient.getPriceTickHistory({
          symbol: props.pair.symbolMajor,
          decimals: props.pair.decimalsMajor,
          tradingPair: props.pair.tradingPair
        }, props.debot)
        .then((value: { history: any}) => {
          const payload = typeof value === 'string' ? JSON.parse(value) : value;
          setHistory(payload.history);
        });
      } else if (!history) {
        DeBotClient.getXchgPriceTickHistory({
          symbolMajor: props.pair.symbolMajor,
          symbolMinor: props.pair.symbolMinor,
          decimalsMajor: props.pair.decimalsMajor,
          decimalsMinor: props.pair.decimalsMinor,
          xchgTradingPair: props.pair.tradingPair
        }, props.debot)
        .then((value: { history: any}) => {
          const payload = typeof value === 'string' ? JSON.parse(value) : value;
          setHistory(payload.history);
        });
      }
    });
    let trades: any[] = [];
    for (const key in history) {
      for (let index = 0; index < history[key].length; index++) {
        trades.push({
          key: key,
          price: DeBotClient.hexToUtf8(history[key][index].price),
          volume: DeBotClient.hexToUtf8(history[key][index].volume)
        });
      }
    }
    trades = trades.reverse();
    let price24HDelta: any = '-';
    let price24HChange: any = '-';
    let lastTrade = '-';
    if (prices && prices.length) {
        price24HChange = (prices[prices.length - 1].price - prices[0].price).toFixed(2);
        price24HDelta = prices[0].price != 0 ? (price24HChange / prices[0].price).toFixed(2) * 100 : '-';
    }
    if (trades && trades.length) {
        lastTrade = trades[0].price*trades[0].volume;
    }

    const pairLink = `${Config.flexUrl}?debot=${props.debot.title.toLowerCase()}&tradingPair=${props.pair.tradingPair}`;

    return (
      <TouchableOpacity onPress={() => window.open(pairLink, '_blank')}>
          <View style={[styles.flex, {flexDirection: 'row', marginTop: 8, alignItems: 'center'}]}>
              <Text style={[{flex: 1}, styles.cardText]}>
                  {`${props.pair.nameMajor} / ${props.pair.nameMinor}`}
              </Text>
              <Text style={[{flex: 1}, styles.cardText]}>
                  {`${lastTrade}`}
              </Text>
              <Text style={[{flex: 1}, styles.cardText]}>
                  {`%${price24HDelta}`}
              </Text>
              <Text style={[{flex: 1}, styles.cardText]}>
                  {`${price24HChange}`}
              </Text>
              <button
                className="btn btn-primary"
                type="button"
                onClick={()=>window.open(pairLink, '_blank')}
              >
                {'Trade'}
              </button>
          </View>
      </TouchableOpacity>
    );
};


const Main:React.FunctionComponent<{}> = () => {
    const flex = {display: 'flex'};
    const CardValue: ReactComponent = (props) => (
      <View style={[props.style]}>
          <Text style={[flex, styles.cardValueTitle]}>
              {props.title}
          </Text>
          <View style={{marginTop: 16}} />
          <Text style={[flex, styles.cardValueText]}>
              {props.text}
          </Text>
      </View>
    );

    const Card: ReactComponent = (props) => (
        <View style={[{padding: 16}, props.style]}>
          <View style={{marginTop: 16}} />
          <Text style={[flex, styles.cardTitle]}>
              {props.title}
          </Text>
          <View style={{marginTop: 4}} />
          <Text style={[flex, styles.cardText]}>
              {props.text}
          </Text>
          <View style={{marginTop: 16}} />
          <button
            className="btn btn-primary"
            type="button"
            onClick={()=>window.open(props.link, '_blank')}
          >
            {props.buttonTitle}
          </button>
        </View>
    );

    return (
      <>
      <MetaDecorator
        title="Flash Exchange"
        description=""
        keywords=""
      />
      <Container fluid>
        <Row>
          <Col xs={{ span: 0, order: 1 }} sm={{ span: 0, order: 1 }} md={{ span: 0, order: 1 }} lg={{ span: 3 }} xl={{ span: 3 }}/>
          <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: 6 }} xl={{ span: 6 }}>
          <View id="landing" style={{padding: 16}}>
              <View style={{marginTop: 90}} />
              <Text style={{...flex, justifyContent: 'center', lineHeight: 56, fontWeight: 700, fontSize: 40, color: 'rgb(32, 38, 42)'}}>
                  Future of DeFi
              </Text>
              <View style={{marginTop: 80}} />
              <Text style={{...flex, lineHeight: 24, fontWeight: 400, fontSize: 20, color: 'rgb(54, 64, 70)'}}>
                  Flash Exchange or Flex is a Free TON decentralized and distributed limit order book (DLOB) with AMM and Liquidity Pools,
                  which takes a most common centralized exchange model: central limit order book (CLOB) and implements it on-chain via a
                  distributed smart contract model.
              </Text>
              <View style={{marginTop: 32}} />
          </View>
          </Col>
          <Col xs={{ span: 0, order: 1 }} sm={{ span: 0, order: 1 }} md={{ span: 0, order: 1 }} lg={{ span: 3 }} xl={{ span: 3 }}/>
        </Row>
        <Row>
          <Col xs={{ span: 0, order: 1 }} sm={{ span: 0, order: 1 }} md={{ span: 0, order: 1 }} lg={{ span: 1 }} xl={{ span: 1 }}/>
          <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: 3 }} xl={{ span: 3 }}>
              <Card link={`${Config.flexUrl}?debot=mainnet`} title={'Trade'} text={'Trade quickly and cheaply.'} buttonTitle={'Enter Exchange'}/>
          </Col>
          <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: 3 }} xl={{ span: 3 }}>
              <Card link={Config.poolUrl} title={'Pool'} text={'Provide liquidity for any Flex token.'} buttonTitle={'Add liquidity'}/>
          </Col>
          <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: 3 }} xl={{ span: 3 }}>
              <Card link={Config.podcastUrl} title={'Killer Whale Pod Cast'} text={'Launchpad for new Flex projects.'} buttonTitle={'View projects'}/>
          </Col>
          <Col xs={{ span: 0, order: 1 }} sm={{ span: 0, order: 1 }} md={{ span: 0, order: 1 }} lg={{ span: 1 }} xl={{ span: 1 }}/>
        </Row>
        {showStats &&
        <Row>
          <Col xs={{ span: 0, order: 1 }} sm={{ span: 0, order: 1 }} md={{ span: 0, order: 1 }} lg={{ span: 4 }} xl={{ span: 4 }}/>
          <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: 2 }} xl={{ span: 2 }}>
              <View style={{marginTop: 64}} />
              <CardValue title={'1,800,000'} text={'Total value locked'} style={{marginLeft: 16}}/>
          </Col>
          <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: 2 }} xl={{ span: 2 }}>
              <View style={{marginTop: 64}} />
              <CardValue title={'23,000,000'} text={'Total trading volume'} style={{marginLeft: 16}}/>
          </Col>
          <Col xs={{ span: 0, order: 1 }} sm={{ span: 0, order: 1 }} md={{ span: 0, order: 1 }} lg={{ span: 4 }} xl={{ span: 4 }}/>
        </Row>
        }
        <DebotTable />
      </Container>
      <View style={{marginTop: 72}} />
      </>
    );
  };

export default Main;
