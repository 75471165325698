import { Dispatch, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { ActionTypes, RootState } from "../../types";
import DeBotClient from "../../client/client";

import {
  PairInfo,
  LinearChart,
  ReadableLinearData,
  DeBot
 } from "../../interfaces";

import {
  setPriceLinearChart
} from "../../redux/actions";

export interface IUsePriceLinearChart {
  priceLinearChart: ReadableLinearData[]
}

export const usePriceLinearChart = (selectedPair: PairInfo | undefined, selectedDeBot: DeBot | undefined): IUsePriceLinearChart => {
  const dispatch = useDispatch<Dispatch<ActionTypes>>();
  const { priceLinearChart } = useSelector((state: RootState) => state.dashboard);

  const normalizedLinearChart: ReadableLinearData[] = [];
  const __linearChart = {...priceLinearChart as LinearChart};
  for (const key in __linearChart) {
    normalizedLinearChart.push({
      date: moment.unix(parseInt(key)).format("YYYY/MM/DD HH:mm:ss"),
      price: parseFloat(DeBotClient.hexToUtf8(__linearChart[key].price)),
      volume  : parseFloat(DeBotClient.hexToUtf8(__linearChart[key].volume))
    });
  }

  useEffect(() => {
    if (selectedPair) {
      if (selectedPair.type === "Tip3TonPairInfo") {
        DeBotClient.getLinearChart({
          symbol: selectedPair.symbolMajor,
          decimals: selectedPair.decimalsMajor,
          tradingPair: selectedPair.tradingPair
        },
        selectedDeBot,
        moment().subtract(25, 'hours').unix(),
        moment().unix(),
        300,
      )
        .then((value: { prices: LinearChart}) => {
          const payload = typeof value === 'string' ? JSON.parse(value) : value;
          dispatch(setPriceLinearChart(payload.prices));
        });
      } else {
        DeBotClient.getXchgLinearChart({
          symbolMajor: selectedPair.symbolMajor,
          symbolMinor: selectedPair.symbolMinor,
          decimalsMajor: selectedPair.decimalsMajor,
          decimalsMinor: selectedPair.decimalsMinor,
          xchgTradingPair: selectedPair.tradingPair
        }, selectedDeBot,
        moment().subtract(25, 'hours').unix(),
        moment().unix(),
        300,
      )
        .then((value: { prices: LinearChart}) => {
          const payload = typeof value === 'string' ? JSON.parse(value) : value;
          dispatch(setPriceLinearChart(payload.prices));
        });
      }
    }
  }, [selectedPair]);


  return useMemo(
    () => ({
      priceLinearChart: normalizedLinearChart
    }),
    [selectedPair, selectedDeBot, priceLinearChart],
  );
};
