import { useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../types";
import DeBotClient from "../../client/client";

import {
  OrderBookRow,
  PairInfo,
  DeBot
 } from "../../interfaces";

export interface IUseOrderbookLinearChart {
  orderbookLinearChart: Array<{price: number, buyVolume: number, sellVolume: number}>,
} 

export const useOrderbookLinearChart = (selectedPair: PairInfo | undefined, selectedDeBot: DeBot | undefined): IUseOrderbookLinearChart => {
  const { orderbook } = useSelector((state: RootState) => state.dashboard);

  const normalizedLinearChart = (orderbook as OrderBookRow[]).map(row => ({
    price: parseFloat(DeBotClient.hexToUtf8(row.price)),
    buyVolume: parseFloat(DeBotClient.hexToUtf8(row.buyVolume)),
    sellVolume: parseFloat(DeBotClient.hexToUtf8(row.sellVolume))
  })).sort((a, b) => a.price - b.price);

  return useMemo(
    () => ({
      orderbookLinearChart: normalizedLinearChart
    }),
    [orderbook, selectedPair, selectedDeBot],
  );
};
