import { Dispatch, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ActionTypes, RootState } from "../../types";
import DeBotClient from "../../client/client";

import {
  PairInfo,
  TradeData,
  TradeHistory,
  DeBot
 } from "../../interfaces";

import {
  setTrades,
  //selectTradesRow,
} from "../../redux/actions";

type ReadableTradeData = TradeData & {key: string};

export interface IUseTrades {
  trades: ReadableTradeData[],
  orderbookMax?: number,
  selectOrder?: any
}

export const useTrades = (selectedPair: PairInfo | undefined, selectedDeBot: DeBot | undefined): IUseTrades => {
  const dispatch = useDispatch<Dispatch<ActionTypes>>();
  const { trades } = useSelector((state: RootState) => state.dashboard);

  const lastTrades: ReadableTradeData[] = [];
  const __trades = trades as TradeHistory;
  for (const key in __trades) {
    for (let index = 0; index < __trades[key].length; index++) {
      lastTrades.push({
        key: key,
        price: DeBotClient.hexToUtf8(__trades[key][index].price),
        volume: DeBotClient.hexToUtf8(__trades[key][index].volume)
      });
    }
  }

  useEffect(() => {
    if (selectedPair) {
      if (selectedPair.type === "Tip3TonPairInfo") {
        DeBotClient.getPriceTickHistory({
          symbol: selectedPair.symbolMajor,
          decimals: selectedPair.decimalsMajor,
          tradingPair: selectedPair.tradingPair
        }, selectedDeBot)
        .then((value: { history: TradeHistory}) => {
          const payload = typeof value === 'string' ? JSON.parse(value) : value;
          dispatch(setTrades(payload.history));
        });
      } else {
        DeBotClient.getXchgPriceTickHistory({
          symbolMajor: selectedPair.symbolMajor,
          symbolMinor: selectedPair.symbolMinor,
          decimalsMajor: selectedPair.decimalsMajor,
          decimalsMinor: selectedPair.decimalsMinor,
          xchgTradingPair: selectedPair.tradingPair
        }, selectedDeBot)
        .then((value: { history: TradeHistory}) => {
          const payload = typeof value === 'string' ? JSON.parse(value) : value;
          dispatch(setTrades(payload.history));
        });
      }

      const tradesInterval = (() => selectedPair ? (
        selectedPair.type === "Tip3TonPairInfo"
          ? setInterval(() => (
            DeBotClient.getPriceTickHistory({
              symbol: selectedPair.symbolMajor,
              decimals: selectedPair.decimalsMajor,
              tradingPair: selectedPair.tradingPair
            }, selectedDeBot)
              .then((value: { history: TradeHistory}) => {
                const payload = typeof value === 'string' ? JSON.parse(value) : value;
                dispatch(setTrades(payload.history));
              })), 10000)
          : setInterval(() => (
            DeBotClient.getXchgPriceTickHistory({
              symbolMajor: selectedPair.symbolMajor,
              symbolMinor: selectedPair.symbolMinor,
              decimalsMajor: selectedPair.decimalsMajor,
              decimalsMinor: selectedPair.decimalsMinor,
              xchgTradingPair: selectedPair.tradingPair
            }, selectedDeBot)
              .then((value: { history: TradeHistory}) => {
                const payload = typeof value === 'string' ? JSON.parse(value) : value;
                dispatch(setTrades(payload.history));
              })), 10000)
      ) : undefined)();

      return () => tradesInterval && clearInterval(tradesInterval);
    }
  }, [selectedPair]);

  return useMemo(
    () => ({
      trades: lastTrades.reverse(),
      orderbookMax: undefined,
      selectOrder: undefined
    }),
    [selectedPair, selectedDeBot, trades],
  );
};
