import React, { useCallback, useRef, useMemo, useState } from 'react';
import { Group } from '@visx/group';
import { scaleTime, scaleLinear, scaleBand } from '@visx/scale';

import { LinePath, Line, Bar } from '@visx/shape';
import { withTooltip, Tooltip, defaultStyles as defaultTooltipStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { GridRows } from '@visx/grid';
import { AxisBottom, AxisRight } from '@visx/axis';
import { localPoint } from '@visx/event';

import { withParentSize } from '@visx/responsive';
import { WithParentSizeProvidedProps } from '@visx/responsive/lib/enhancers/withParentSize';

import { ReadableCandlestickData } from '../../../interfaces';

import HoverLine from './components/hoverline';
import TimeMarker from './components/timemarker';
import Details from './components/details';

import { format } from 'd3-format';
import moment from 'moment';
const formatNumber = format(',.0f');
const formatPrice = (value: number) => format(',.2f')(value);
const formatDate = (value: string) => moment(value).format("h:mmA");

// accessors
// const x = (d: Stats) => d.boxPlot.x;
// const min = (d: Stats) => d.boxPlot.min;
// const max = (d: Stats) => d.boxPlot.max;
// const median = (d: Stats) => d.boxPlot.median;
// const firstQuartile = (d: Stats) => d.boxPlot.firstQuartile;
// const thirdQuartile = (d: Stats) => d.boxPlot.thirdQuartile;
// const outliers = (d: Stats) => d.boxPlot.outliers;

interface TooltipData {
    date: string;
    open: number;
    low: number;
    high: number;
    close: number;
    volume: number;
    name?: string;
    min?: number;
    median?: number;
    max?: number;
    firstQuartile?: number;
    thirdQuartile?: number;
  }
  
  export type ChartProps = {
    data: ReadableCandlestickData[],
    currency: string
  };
  
export type WithParentSizeProps = {
  debounceTime?: number;
  enableDebounceLeadingCall?: boolean;
};

  
  export default withParentSize<WithParentSizeProps & ChartProps>(withTooltip<ChartProps, TooltipData>(
    ({
      data,
      currency,
      parentWidth = 600,
      parentHeight = 400,
      tooltipOpen,
      tooltipLeft,
      tooltipTop,
      tooltipData,
      showTooltip,
      hideTooltip
    }: ChartProps & WithParentSizeProvidedProps & WithTooltipProvidedProps<TooltipData>) => {

    if (
      !parentWidth ||
      !data ||
      !data.length
    ) return <div style={{padding: 15}}>Loading...</div>;

    const svgContainer = useRef<SVGSVGElement>(null);
    const [activeBucket, setActiveBucket] = useState<{
      activeBucket: ReadableCandlestickData,
      x: number,
      y: number,
    } | undefined>(undefined);


    const margin = {
      top: 50,
      bottom: 40,
      left: 30,
      right: 75,
    }
    const width = parentWidth - margin.right;
    const height = parentHeight - margin.top - margin.bottom;

    const dataCount = Math.floor(width/15);

  const data_ = data.slice(0, dataCount).map(q => ({hollow: q.close < q.open, ...q}));



    const date = (d:ReadableCandlestickData) => moment(d.date).toDate();
    const high = (d:ReadableCandlestickData) => d.high;
    const low = (d:ReadableCandlestickData) => d.low;
    const open = (d:ReadableCandlestickData) => d.open;
    const close = (d:ReadableCandlestickData) => d.close;
    const volume = (d:ReadableCandlestickData) => d.volume;
    // const close = d => d.price;
    // const bisectDate = bisector(d => x(d)).left;

    const firstPoint = data_[0];
    const currentPoint = data_[data_.length - 1];
    const minPrice = Math.min(...data_.map(low), ...data_.map(open), ...data_.map(close));
    const maxPrice = Math.max(...data_.map(high), ...data_.map(open), ...data_.map(close));
    const maxVolume = Math.max(...data_.map(volume), ...data_.map(volume), ...data_.map(volume));
    
    const bandScale = useMemo(
      () =>
      scaleBand<number>({
          range: [margin.left, width - 10],
          domain: data_.map(b => date(b).valueOf()),
        }),
      [width, data_],
    );
    const xScale = useMemo(
      () =>
      scaleTime<number>({
          range: [margin.left + 30, width - 40],
          domain: [date(firstPoint).valueOf(), date(currentPoint).valueOf()]
        }),
      [width, data_],
    );

    const yScale = useMemo(
      () =>{
        if (minPrice === 0 && maxPrice === 0) return scaleLinear<number>({
          range: [height, 0],
          domain: [0, 16]
        })
        return scaleLinear<number>({
          range: [height, 0],
          domain: [minPrice, maxPrice]
        })
      },
      [height, minPrice, maxPrice, data_],
    );

    const barWidth = bandScale.bandwidth() - 4 > 10 ? 10 : bandScale.bandwidth() - 4;
  
    const handleTooltip = useCallback(
      (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
        const { x, y } = localPoint(event) || { x: 0 };

        const bandWidth = bandScale.step();
        const stepIndex = x - margin.left >= 0 ? (Math.floor((x - margin.left) / bandWidth) < data_.length ? Math.floor((x - margin.left) / bandWidth) : data_.length - 1) : 0;
        const bucket = data_[stepIndex];
        setActiveBucket({
          activeBucket: bucket,
          x: stepIndex * bandWidth,
          y: yScale(open(bucket))
        });

        showTooltip({
          tooltipData: bucket,
          tooltipLeft: x,
          tooltipTop: y,
        });
          
      },
      [showTooltip, yScale, xScale, data_],
    );

      return (<div style={{position: "relative"}}>
      <svg
        width={parentWidth}
        height={parentHeight}
        ref={svgContainer}
      >

        <Group top={margin.top} left={0}>
          <GridRows
            width={width}
            height={height}
            scale={yScale}
            numTicks={8}
          />
          {data_.map((b, index) => {
            const color = b.hollow ? "#e23b3b" : "#36C05C";
            return (
              <g key={`b-${b.date.valueOf()}-${index}`}>
                <Line
                  from={{
                    x: bandScale(date(b).valueOf())! + bandScale.bandwidth() / 2,
                    y: b.hollow ? yScale(close(b)) : yScale(open(b))
                  }}
                  to={{
                    x: bandScale(date(b).valueOf())! + bandScale.bandwidth() / 2,
                    y: yScale(low(b))
                  }}
                  stroke={color}
                  strokeWidth={2}
                  strokeLinecap={"round"}
                  style={{ pointerEvents: 'none' }}
                />
                <Line

                  from={{
                    x: bandScale(date(b).valueOf())! + bandScale.bandwidth() / 2,
                    y: yScale(high(b))
                  }}
                  to={{
                    x: bandScale(date(b).valueOf())! + bandScale.bandwidth() / 2,
                    y: b.hollow ? yScale(open(b)) :yScale(close(b))
                  }}
                  stroke={color}
                  strokeWidth={2}
                  strokeLinecap={"round"}
                  style={{ pointerEvents: 'none' }}
                />
                <Bar
                  width={barWidth}
                  height={(Math.abs(yScale(open(b)) - yScale(close(b))) >= 2) ? Math.abs(yScale(open(b)) - yScale(close(b))) : (open(b) ? 3 : 0)}
                  fill={color + "99"}
                  stroke={color}
                  rx={2}
                  ry={2}
                  strokeLinecap={"round"}
                  strokeWidth={2}
                  x={bandScale(date(b).valueOf())! + bandScale.bandwidth()/2 - barWidth/2}
                  y={b.hollow ? yScale(open(b)) - 1 : yScale(close(b)) - 1}
                />
                <Bar
                  width={barWidth}
                  height={50*volume(b)/(maxVolume + 0.0001)}
                  x={bandScale(date(b).valueOf())! + bandScale.bandwidth()/2 - barWidth/2}
                  y={height  - 50*volume(b)/(maxVolume + 0.0001)}
                  fill={color}
                  stroke={'transparent'}
                  fillOpacity={0.3}
                  strokeOpacity={0.3}
                />
              </g>
            );
          })}

        </Group>
           <Group top={margin.top} left={0} width={parentWidth} height={parentHeight}>
            <AxisBottom
              scale={bandScale}
              top={height + 10}
              left={0}
              numTicks={6}
              hideAxisLine
              hideTicks
              tickFormat={(value) => moment.unix(Math.floor(value/1000)).format("h:mmA")}
              tickLabelProps={() => ({
                fill: "#707376",
                fontSize: 13,
                textAnchor: 'middle',
              })}
            />
            <AxisRight
              scale={yScale}
              top={5}
              left={width + 30}
              numTicks={8}
              hideAxisLine
              hideTicks
              tickLabelProps={() => ({
                fill: "#707376",
                fontSize: 13,
                textAnchor: 'middle',
              })}
            />

             <LinePath
               data={data}
               y={close}
               x={(x) => date(x).valueOf()}
               stroke="#6086d6"
               strokeOpacity="0.8"
               strokeWidth={10}
             />
  
            <Bar
              width={width}
              height={height - margin.bottom}
              fill="transparent"
              onTouchStart={handleTooltip}
              onTouchMove={handleTooltip}
              onMouseMove={handleTooltip}
              onMouseLeave={() => {
                hideTooltip()
                setActiveBucket(undefined)
              }}
            />
           </Group>

           {tooltipData && activeBucket && <>
            <HoverLine
              size={{
                line: barWidth,
                width: width,
                height: height + margin.bottom + 20 
              }}
              tooltipLeft={bandScale(date(activeBucket!.activeBucket).valueOf())! + bandScale.bandwidth() / 2}
              tooltipTop={tooltipTop}
            />
            </>}
      </svg>

      {activeBucket && activeBucket.activeBucket && tooltipData &&
          <div>
            <TimeMarker
              top={height + margin.top + 7}
              left={bandScale(date(activeBucket!.activeBucket).valueOf())! + bandScale.bandwidth() / 2 + barWidth}
              format={formatDate}
              value={date(activeBucket!.activeBucket).valueOf()}
              style={{
                marginLeft: '-20px',
              }}
            />
            
            <Details
              width={width}
              xScale={bandScale}
              formatPrice={formatPrice}
              formatNumber={formatNumber}
              bucket={activeBucket!.activeBucket}
            />

            <Tooltip
              style={{
                ...defaultTooltipStyles,
                width: 'auto',
                borderRadius: 10,
                padding: '8px 12px',
                boxShadow: '0 1px 10px rgba(0,0,0,0.1)',
                backgroundColor: '#27273fcc',
                color: 'white',
                textAlign: 'center',
                transition: '0.15s cubic-bezier(0.375, 0.885, 0.6, 1) all',
                zIndex: 1000,
                transform: xScale(date(activeBucket!.activeBucket)) > (xScale.range()[1] * 3 / 4) ? "translate(-170%)" : "",
              }}
              top={yScale(low(activeBucket!.activeBucket)) - 20}
              left={bandScale(date(activeBucket!.activeBucket).valueOf())! + bandScale.bandwidth() / 2 + barWidth}
            >
              {close(activeBucket!.activeBucket) - open(activeBucket!.activeBucket) > 0 ? '+' : ''}{formatPrice(close(activeBucket!.activeBucket) - open(activeBucket!.activeBucket) )}
            </Tooltip>
          </div>}
    </div>);
    },
  )
);