import { Line } from '@visx/shape';

const HoverLine = ({ size, tooltipLeft, tooltipTop }) => {

  const fromHorizontal = {
    x: 0,
    y: tooltipTop
  }
  const toHorizontal = {
    x: size.width,
    y: tooltipTop
  }
  const fromVertical = {
    x: tooltipLeft,
    y: size.height + 20
  }
  const toVertical = {
    x: tooltipLeft,
    y: 40
  }
  return (
    <>
      <Line
        from={fromVertical}
        to={toVertical}
        stroke="#000000"
        strokeWidth={size.line}
        style={{ pointerEvents: 'none' }}
        fillOpacity={0.05}
        strokeOpacity={0.05}
      />
      <Line
        from={fromHorizontal}
        to={toHorizontal}
        stroke="#000000"
        strokeWidth={1}
        style={{ pointerEvents: 'none' }}
        fillOpacity={0.3}
        strokeOpacity={0.3}
        strokeDasharray="2,2"
      />
      <circle
        cx={tooltipLeft}
        cy={tooltipTop}
        r={8}
        fill="#36C05C"
        fillOpacity={0.2}
        style={{ pointerEvents: 'none' }}
      />
      <circle
        cx={tooltipLeft}
        cy={tooltipTop}
        r={4}
        fill="#36C05C"
        style={{ pointerEvents: 'none' }}
      />
    </>
  );
};

export default HoverLine;