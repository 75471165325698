import { Dispatch, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ActionTypes, RootState } from "../../types";
import DeBotClient from "../../client/client";

import {
  OrderBookRow,
  PairInfo,
  DeBot
 } from "../../interfaces";

import {
  setOrderbook,
  selectOrderbookRow,
} from "../../redux/actions";

export interface IUseOrderbook {
  orderbook: Array<{price: number, buyVolume: number, sellVolume: number}>,
  orderbookMax?: number,
  selectOrder?: any
} 


export const useOrderbook = (selectedPair: PairInfo | undefined, selectedDeBot: DeBot | undefined): IUseOrderbook => {
  const dispatch = useDispatch<Dispatch<ActionTypes>>();
  const { orderbook } = useSelector((state: RootState) => state.dashboard);

  const __orderbook = (orderbook as OrderBookRow[]).map(row => ({
    price: parseFloat(DeBotClient.hexToUtf8(row.price)),
    buyVolume: parseFloat(DeBotClient.hexToUtf8(row.buyVolume)),
    sellVolume: parseFloat(DeBotClient.hexToUtf8(row.sellVolume))
  })).sort((a, b) => b.price - a.price);

  const countSellDeals = __orderbook.filter(row => row.sellVolume).length;

  if (countSellDeals < 5) 
    __orderbook.splice(10)
  else {
    __orderbook.splice(0, countSellDeals -5);  
    __orderbook.splice(10);
  }

  const __orderbookMax = __orderbook.reduce((a, b) => {
    const volume = b.price * (b.buyVolume || b.sellVolume);
    return (a > volume
      ? a 
      : volume
    );
  }, 0);

  const selectOrder = (value:OrderBookRow) => {
    dispatch(selectOrderbookRow(value));
  }

  useEffect(() => {
    if (selectedPair) {
      if (selectedPair.type === "Tip3TonPairInfo") {
        DeBotClient.getOrderBook({
          symbol: selectedPair.symbolMajor,
          decimals: selectedPair.decimalsMajor,
          tradingPair: selectedPair.tradingPair
        }, selectedDeBot)
        .then((value) => {
          const payload = typeof value === 'string' ? JSON.parse(value) : value;
          dispatch(setOrderbook(payload.orderBook));
        });
      } else {
        DeBotClient.getXchgOrderBook({
          symbolMajor: selectedPair.symbolMajor,
          symbolMinor: selectedPair.symbolMinor,
          decimalsMajor: selectedPair.decimalsMajor,
          decimalsMinor: selectedPair.decimalsMinor,
          xchgTradingPair: selectedPair.tradingPair
        }, selectedDeBot)
        .then((value) => {
          const payload = typeof value === 'string' ? JSON.parse(value) : value;
          dispatch(setOrderbook(payload.orderBook));
        });
      }
    }
    const orderbookInterval = (() => selectedPair ? (
      selectedPair.type === "Tip3TonPairInfo"
        ? setInterval(() => (
            DeBotClient.getOrderBook({
            symbol: selectedPair.symbolMajor,
            decimals: selectedPair.decimalsMajor,
            tradingPair: selectedPair.tradingPair
          }, selectedDeBot)
          .then((value) => {
            const payload = typeof value === 'string' ? JSON.parse(value) : value;
            dispatch(setOrderbook(payload.orderBook));
          })), 10000)
        : setInterval(() => (
            DeBotClient.getXchgOrderBook({
            symbolMajor: selectedPair.symbolMajor,
            symbolMinor: selectedPair.symbolMinor,
            decimalsMajor: selectedPair.decimalsMajor,
            decimalsMinor: selectedPair.decimalsMinor,
            xchgTradingPair: selectedPair.tradingPair
          }, selectedDeBot)
          .then((value) => {
            const payload = typeof value === 'string' ? JSON.parse(value) : value;
            dispatch(setOrderbook(payload.orderBook));
          })), 10000)
    ) : undefined)();
    return () => orderbookInterval && clearInterval(orderbookInterval);
  }, [selectedPair]);

  return useMemo(
    () => ({
      orderbook: __orderbook,
      orderbookMax: __orderbookMax,
      selectOrder
    }),
    [selectedPair, selectedDeBot, orderbook],
  );
};
