import { ActionTypes } from "../../types/action-types";
import {
  SET_PAIRS,
  SET_NORMALIZED_PAIRS,
  SET_PAIR,
  SET_DEBOTS,
  SET_DEBOT,
  SET_ORDERBOOK,
  SET_TRADES,
  SELECT_ORDERBOOK_ROW,
  SET_EXCHANGE_MODE,
  SET_ORDER_MESSAGE,
  SET_CANDLESTICK_CHART,
  SET_LINEAR_CHART,
  SET_PRICE_LINEAR_CHART
} from "../actions/constants";

import {
  Pairs,
  PairInfo,
  DeBot,
  TradeHistory,
  OrderBookRow,
  XchgOrderBookRow,
  exchangeMode,
  OrderMessage,
  LinearChart,
  CandlestickChart
} from "../../interfaces";

interface IInitialState {
  pairs: Pairs,
  normalizedPairs: PairInfo[],
  selectedPair: PairInfo | undefined,
  selectedDeBot: DeBot | undefined,
  debots: DeBot[],
  orderbook: OrderBookRow[] | XchgOrderBookRow[],
  trades: TradeHistory,
  selectedOrderbookRow: OrderBookRow | undefined,
  exchangeMode: exchangeMode,
  orderMessage: OrderMessage | undefined,
  linearChart: LinearChart | undefined,
  priceLinearChart: LinearChart | undefined,
  candlestickChart: CandlestickChart | undefined,
}

const initialState: IInitialState = {
  pairs: {
    tip3tip3Pairs: [],
    tip3tonPairs: []
  },
  normalizedPairs: [],
  selectedPair: undefined,
  selectedDeBot: undefined,
  debots: [],
  orderbook: [],
  trades: {},
  selectedOrderbookRow: undefined,
  exchangeMode: 'buy',
  orderMessage: undefined,

  linearChart: undefined,
  priceLinearChart: undefined,
  candlestickChart: undefined,
};

export const dashboardReducer = (
  state: IInitialState = initialState,
  { type, payload }: ActionTypes
):IInitialState => {
  switch (type) {
    case SET_PAIRS:
      return { ...state, pairs: payload as Pairs};
    case SET_NORMALIZED_PAIRS:
      return { ...state, normalizedPairs: payload as PairInfo[]};
    case SET_PAIR:
      return { ...state, selectedPair: payload as PairInfo};
    case SET_DEBOTS:
      return { ...state, debots: payload as Array<DeBot>};
    case SET_DEBOT:
      return { ...state, selectedDeBot: payload as DeBot};
    case SET_ORDERBOOK:
      return { ...state, orderbook: payload as Array<OrderBookRow>};
    case SELECT_ORDERBOOK_ROW:
      return { ...state, selectedOrderbookRow: payload as OrderBookRow};
    case SET_EXCHANGE_MODE:
      return { ...state, exchangeMode: payload as exchangeMode};
    case SET_TRADES:
      return { ...state, trades: payload as TradeHistory};
    case SET_ORDER_MESSAGE:
      return { ...state, orderMessage: payload as OrderMessage};
    case SET_LINEAR_CHART:
      return { ...state, linearChart: payload as LinearChart};
    case SET_PRICE_LINEAR_CHART:
      return { ...state, priceLinearChart: payload as LinearChart};
    case SET_CANDLESTICK_CHART:
      return { ...state, candlestickChart: payload as CandlestickChart};
    default:
      return state;
  }
};