import { Dispatch, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { ActionTypes, RootState } from "../../types";
import DeBotClient from "../../client/client";

import {
  PairInfo,
  CandlestickChart,
  DeBot,
  ReadableCandlestickData
 } from "../../interfaces";

import {
  setCandlestickChart
} from "../../redux/actions";
export interface IUseCandlestick {
  candlestickChart: ReadableCandlestickData[]
}

export const useCandlestick = (selectedPair: PairInfo | undefined, selectedDeBot: DeBot | undefined): IUseCandlestick => {
  const dispatch = useDispatch<Dispatch<ActionTypes>>();
  const { candlestickChart } = useSelector((state: RootState) => state.dashboard);

  const normalizedCandlestickChart: ReadableCandlestickData[] = [];
  const __candlestickChart = {...candlestickChart as CandlestickChart};
  for (const key in __candlestickChart) {
    normalizedCandlestickChart.push({
      date: moment.unix(parseInt(key)).format("YYYY/MM/DD HH:mm:ss"),
      open: parseFloat(DeBotClient.hexToUtf8(__candlestickChart[key].open)),
      low: parseFloat(DeBotClient.hexToUtf8(__candlestickChart[key].low)),
      high: parseFloat(DeBotClient.hexToUtf8(__candlestickChart[key].high)),
      close: parseFloat(DeBotClient.hexToUtf8(__candlestickChart[key].close)),
      volume  : parseFloat(DeBotClient.hexToUtf8(__candlestickChart[key].volume))
    });
  }

  useEffect(() => {
    if (selectedPair) {
      if (selectedPair.type === "Tip3TonPairInfo") {
        DeBotClient.getCandlestickChart({
          symbol: selectedPair.symbolMajor,
          decimals: selectedPair.decimalsMajor,
          tradingPair: selectedPair.tradingPair,
        },
        selectedDeBot,
        moment().subtract(25, 'hours').unix(), //startTime
        //moment().subtract(31, 'days').unix(), //startTime
        moment().unix(), //endTime
        3600, //stepTime
        //86400, //stepTime
        )
        .then((value: { candles: CandlestickChart}) => {
          const payload = typeof value === 'string' ? JSON.parse(value) : value;
          dispatch(setCandlestickChart(payload.candles));
        });
      } else {
        DeBotClient.getXchgCandlestickChart({
          symbolMajor: selectedPair.symbolMajor,
          symbolMinor: selectedPair.symbolMinor,
          decimalsMajor: selectedPair.decimalsMajor,
          decimalsMinor: selectedPair.decimalsMinor,
          xchgTradingPair: selectedPair.tradingPair
        },
        selectedDeBot,
        moment().subtract(25, 'hours').unix(), //startTime
        //moment().subtract(31, 'days').unix(), //startTime
        moment().unix(), //endTime
        3600, //stepTime
        //86400,
        )
        .then((value: { candles: CandlestickChart}) => {
          const payload = typeof value === 'string' ? JSON.parse(value) : value;
          dispatch(setCandlestickChart(payload.candles));
        });
      }
    }
  }, [selectedPair]);

  return useMemo(
    () => ({
      candlestickChart: normalizedCandlestickChart
    }),
    [selectedPair, selectedDeBot, candlestickChart],
  );
};
