export default {
    landingUrl: process.env.REACT_APP_MODE === 'test' ?
        'https://flex-landing.web.app/' :
        'https://flex.ton.surf/',
    flexUrl: process.env.REACT_APP_MODE === 'test' ?
        'https://flex-trade.web.app/' :
        'https://trade.flex.ton.surf/',
    podcastUrl: 'https://kwpc.show/',
    poolUrl: '/',
    docsUrl: 'https://tonlabs.notion.site/tonlabs/Flex-Help-1f1908ab6277473982fc60f4d798478b',
    isLandingPage: process.env.REACT_APP_SITE === 'landing',
    isTest: process.env.REACT_APP_MODE === 'test',
};
