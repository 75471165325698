import { FunctionComponent } from "react";
import { Container, Nav } from "react-bootstrap";
import Config from '../../config';

const Footer: FunctionComponent<{}> = () => {

  if (Config.isLandingPage) return (
    <footer>
      <Container fluid  style={{justifyContent: 'center'}}>
        <div className="copyright">
          built on <a href='https://freeton.org/' target='_blank' style={{color: '#000000'}}><strong>Free TON</strong></a>
        </div>
      </Container>
    </footer>
  );
  return (
    <footer>
      <Container fluid>
        <Nav as="ul">
          {/* <Nav.Item as="li">
            <Link to="/legal-notes/surf-decentralization-policy" className="dod">Surf Decentralization Policy</Link>
          </Nav.Item> */}
        </Nav>
        <div className="copyright">2K{(new Date()).getFullYear().toString().substring(2)} &#169; TON Surf</div>
      </Container>
    </footer>
  );
};

export default Footer;
