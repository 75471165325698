import Marker from './marker';

export default function TimeMarker({ top, left, value, format, style = {}}) {
  return (
    <Marker
      style={{
        marginLeft: '-10px',
        transform: 'translateX(-50%)',
        ...style
      }}
      value={value}
      top={top}
      left={left}
      format={format}
    />);
}
